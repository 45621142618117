body{
  margin: 0;
}



.App-logo {
  max-height: 9vh;
  color: white;
}
.MyAppBar {
  background-color: black;
}

.HeaderImgsection {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  background-image: url('./IVAZI_Master.jpg');
  /*background-image: url('./IVAZI_Master.jpg');*/
  height: 750px;
}
.MuiAppBar-root{
  background: black !important;
  border-bottom: 1px solid;
  border-color: #f3f3f3;
}

.ImgIcon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: black;
  background-image: url('./IVAZI_House.jpg');
  /* background-image: url('./IVAZI_Master.jpg'); */
  height: 64px;
   padding-top: 2px;
}

.ServicesID {
  margin-top: 90px;
  margin-bottom: 70px;
}
.serviceHeader{
  margin-top: 75px;
  margin-bottom: 70px;
}
.serviceHeader span {
  font-size: 3rem;
}
#Services{
  margin: 10px;
  padding-top: 3px;
}
a:-webkit-any-link {text-decoration: none;}
.ServiceDivider::before { border-color: black !important; }
.ServiceDivider::after { border-color: black !important; }
.AboutDivider::before { border-color: black !important; }
.AboutDivider::after { border-color: black !important; }
.ContactDivider::before {
  border-color: #f3f3f3 !important;
  }
.ContactDivider::after { border-color: #f3f3f3 !important; }

.MuiDivider-wrapper div {
  height: auto ;
}

.AboutID {
  margin-top: 70px;
  margin-bottom: 70px;
}
.AboutHeader {
  margin-top: 70px;
  margin-bottom: 70px;
}
.AboutHeader span {
  font-size: 3rem;
}
.ContactHeader {
  margin-top: 70px;
  margin-bottom: 70px;
}
.ContactHeader span {
  font-size: 3rem;
}
.ContactID {
  margin-top: 70px;
  /* margin-bottom: 70px; */
}

.ContactDivider{ padding-top: 50px}
.TestingContact {background-color: black;}

@media only screen and (max-width: 600px) {
  .serviceHeader span { font-size: 2.3rem; }
  .AboutHeader span { font-size: 2.3rem; }
  .ContactHeader span { font-size: 2.3rem; }
  .MuiTypography-h6		{font-size: 1rem !important;}
  .MuiTypography-h4		{font-size: 1.7rem !important;}
  .ServicesID {  margin-top: 70px; margin-bottom: 20px;
  }
}
footer {
  height: 100px;
  background-color: black;
}
ul{
  background-color: black !important;
  color: white;
}
ul li {
  font-size: 3rem;
}
ul li a {
  color: white;
}
ul li a:hover {
  color: blue;
}
.ListDivider{
  border-color: white !important;
}
.MuiSvgIcon-root{
  font-size: 2.5rem !important;
}
.MuiListItem-root span {
  font-size: 1.25rem;
}
.contactGrid{
  padding-top: 10px !important;
  padding-left: 5px !important;
}
